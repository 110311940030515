body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: 'MontserratFont', sans-serif !important;
}

:root {
  /*Переменные для основного экрана*/
  --header-height: 6.25rem;
  --padding-size: 2rem;
  --main-height: calc(100dvh - var(--header-height) - (var(--padding-size) * 2));
  --scroll-bar-width: 10px;
  --max-screen-width: 1920px;
  --mobile-header-height: 40px;

  /*Переменные для таблицы товаров*/
  --header-table-height: 2.5rem;
}

@font-face {
  font-family: 'MontserratFont';
  src: local('MontserratFont'), url(./assets/fonts/MontserratFont.ttf) format('truetype');
}
